import React from "react"
import { graphql } from "gatsby"

import { Layout, PostPreview, SEO } from "../components"

const BlogIndex = (props) => {
  const _posts = props.data.allOrgContent.edges
  const posts = _posts.map(({ node }) => {
    const post = node
    const { title, date, tags, image, description } = post.metadata
    const attrs = {
      title,
      date,
      content: post.html,
      snippet: post.snippet,
      tags,
      slug: post.fields.slug,
      image,
      description,
      wordCount: post.wordCount,
      timeToRead: post.timeToRead,
    }
    return <PostPreview key={post.fields.slug} {...attrs} />
  })

  return (
    <Layout>
      <SEO title="Blog" />
      {posts}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    allOrgContent(sort: { fields: metadata___date, order: DESC }) {
      edges {
        node {
          html
          snippet
          fields {
            slug
          }
          metadata {
            title
            date(formatString: "ll")
            tags
            image
            description
          }
          wordCount
          timeToRead
        }
      }
    }
  }
`
